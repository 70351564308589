export default{
    name: 'hotel-booking',
    data(){
        const fromDateMinimum = new Date();
        fromDateMinimum.setDate(fromDateMinimum.getDate() + 1);
        return{
            numberAdults: 1,
            options:[1,2,3,4],
            toDate: null,
            fromDate: null,
            fromDateMin: fromDateMinimum,
            toDateState: null,
            fromDateState: null
        }
    },
    methods:{
        onSubmit(){
            this.fromDateState = !this.fromDate ? false : true;
            this.toDateState = !this.toDate ? false : true;
            if(this.fromDate && this.toDate){
                this.$http.post('/hotel-booking', {
                    check_in: this.fromDate,
                    check_out: this.toDate,
                    no_of_adults: this.numberAdults
                }).then(response =>{
                    if(response.status == 200){
                        this.$helper.showMessage(response.data.message);
                    }
                }).catch( err =>{
                    console.error( err )
                })
            }
        }
    }
}
